import yxpapi from '@yxpapi';

let apis = new yxpapi.PHPAPI({
    // 机型列表
    getProductList: {
        url: 'https://youxueketang.anoah.com/api/crm/website/official/product/machine-list',
        // url: 'http://yapi.dev.anoah.com/mock/1240/crm/website/official/product/machine-list',
        method: 'get',
        options: {
            auth: false,
        },
    },
    // 获取轮播图
    getTotate: {
        url: 'https://youxueketang.anoah.com/api/crm/website/official/product/top-image',
        // url: 'http://yapi.dev.anoah.com/mock/1240/crm/website/official/product/top-image',
        method: 'get',
        options: {
            auth: false,
        },
    },
});
export default apis;
