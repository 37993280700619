<template>
    <div class="product-container">
        <div class="product-banner" :style="{ 'background-image': 'url(' + topImg + ')' }"></div>
        <div class="content">
            <div class="product-list-container" v-if="contentList.length">
                <div
                  class="product-list"
                  v-for="item in contentList"
                  :key="item.contentId"
                  @click="viewDetail(item.contentLink)"
                >
                    <div class="img-box">
                        <img :src="item.imageUrl">
                    </div>
                    <!-- <span>{{ item.title }}</span> -->
                </div>
                <div class="placeholder-ele"></div>
                <div class="placeholder-ele"></div>
                <div class="placeholder-ele"></div>
            </div>
            <div class="empty-page" v-else>
                <img src="@/assets/images/icon_empty.png">
            </div>
        </div>
    </div>
</template>
<script>
import APIS from '@/api/api.js';
export default {
    name: 'Product',
    data () {
        return {
            topImg: '', // 顶部图片
            contentList: [],
        };
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            vm.contentList = [];
            vm.getTotate();
            vm.getProductList();
        });
    },
    mounted () {
    },
    methods: {
        // 获取机型列表
        getProductList () {
            APIS.getProductList().then(res => {
                if (res.errcode === 0) {
                    res.data.list.forEach(item => {
                        this.$set(item, 'imageUrl', `${item.image.split(';')[0]}`);
                        this.contentList.push(item);
                    });
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 获取轮播
        getTotate () {
            APIS.getTotate().then(res => {
                if (res.errcode === 0) {
                    this.topImg = (res.data && res.data.image) || '';
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 查看机型详情
        viewDetail (url) {
            if (url) {
                window.open(url);
            }
        },
    },
};
</script>
<style scoped lang="scss">
    .product-container {
        .product-banner {
            width: 100%;
            height: 375px;
            background: #f7f7f7;
            background-repeat: no-repeat;
            background-position: center;
        }
        .content {
            width: 1200px;
            margin: 0 auto;
            .product-list-container {
                padding-top: 20px;
                display: flex;
                flex-wrap: wrap;
                .product-list {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;
                    margin: 20px;
                    padding: 10px;
                    cursor: pointer;
                    border-radius: 4px;
                    width: 355px;
                    &:hover {
                        box-shadow: 3px 5.196px 12px 0px rgba(51, 51, 51, 0.2)
                    }
                    .img-box {
                        width: 355px;
                        height: 355px;
                        // background: #f7f7f7;
                        border-radius: 6px;
                        flex-shrink: 0;
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: scale-down;
                        }
                    }
                    span {
                        margin-top: 10px;
                        color: #333;
                        font-size: 16px;
                        display: -webkit-box;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                    }
                }
                .placeholder-ele {
                    width: 355px;
                    height: 0;
                }
            }
            .empty-page {
                text-align: center;
                padding: 100px 0;
                img {
                    width: 200px;
                }
            }
        }
    }
    @media screen and (max-width: 1279px) {
        .product-container {
            .content {
                width: 900px;
                .product-list-container {
                    .product-list {
                        width: 234px;
                        .img-box {
                            width: 234px;
                            height: 230px;
                        }
                    }
                    .placeholder-ele {
                        width: 234px;
                        height: 0;
                    }
                }
            }
        }
    }
</style>
